<template>
  <div>
    <h4>档案调阅授权同意书</h4>
    <div>
      <div class="subhead">
        广东省全民健康信息综合管理平台联通市全民健康信息平台、医疗卫生机构，采集健康医疗信息，建立全省医疗健康档案大数据平台，为各医疗机构提供跨层级、跨机构的数据服务，实现广东省医疗健康数据的共享和调阅，为医护人员的诊疗业务提供辅助支持用信息化工具切实有效
        提升群众就医效率
      </div>
      <div class="subhead">一、为此，本人做出以下授权:</div>
      <p class="indentation">
        (一)
        本人同意广东省全民健康信息综合管理平台采集本人诊疗信息并成为本人的终身诊疗档案。
      </p>
      <!--      <p>-->
      <!--       -->
      <!--      </p>-->
      <p class="indentation">
        (二)如本人账号已与亲属账号绑定的，本人同意由该亲属代替本人作出上述档案调阅授权。
      </p>
      <p class="indentation">
        (三)本人同意台山市人民医院调阅本人在广东省全民健康信息综合管理平台诊疗信息，调阅授权期限
        30天，自本人在授权同意之日起。
      </p>
      <div class="subhead">
        二、本人已知悉并完全理解本授权书的所有内容并自愿作出上述授权，本授权书是本人真实意愿，本人同意承担由此带来的一切后果。
      </div>
      <p style="text-indent: 2em;">特此授权!</p>
      <div class="dRight test">授权人:{{ patientName }}</div>
      <div class="dRight">授权时间:{{ time }}</div>
      <div class="butt">
        <van-button type="info" :disabled="empo" @click="empower"
          >已阅读并同意授权</van-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { jsonPost, formPost } from "@/baseAPI";
import dayjs from "dayjs";
import qs from "qs";
import { Dialog, Toast } from "vant";
export default {
  data() {
    return {
      time: "",
      patientIdCard: "",
      patientName: "",
      empo: false
    };
  },
  created() {
    this.time = dayjs().format("YYYY-MM-DD HH:mm:ss");
    let str = window.location.href;
    let patientName = this.getQueryStringValue(str, "patientName");
    this.patientName = decodeURIComponent(patientName);
    this.patientIdCard = this.getQueryStringValue(str, "patientIdCard");
  },
  methods: {
    getQueryStringValue(url, key) {
      const match = url.match(new RegExp("[?&]" + key + "=([^&]+)"));
      return match ? match[1] : null;
    },
    async empower() {
      this.empo = true;
      let data = {
        patientIdCard: this.patientIdCard,
        patientName: this.patientName
      };
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true
      });
      const { data: res } = await formPost(
        "/wxapp/his/authRec",
        qs.stringify(data)
      );
      this.$toast.clear();
      if (res.code === 0) {
        Dialog.alert({
          title: "提示",
          message: "授权成功,请点击确认关闭网页"
        }).then(() => {
          // on close
          window.close();
          WeixinJSBridge.call('closeWindow');
        });
      } else {
        this.empo = false;
        Toast.fail("授权失败,请重新授权");
      }
    }
  }
};
</script>
<style lang="less">
h4 {
  margin: 0 auto;
  text-align: center;
  padding: 60px 0 35px;
}
.subhead {
  text-indent: 2em;
  font-size: 35px;
  color: #130805;
  font-weight: bold;
}
p {
  font-size: 35px;
  color: #130805;
  font-weight: bold;
}
.indentation {
  text-indent: 3em;
  color: #130805;
  font-weight: bold;
}
.dRight {
  text-align: right;
  font-size: 35px;
  color: #130805;
  font-weight: bold;
  padding: 20px 20px 0;
}
.test {
  margin-right: 100px;
}
.butt {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  .van-button {
    width: 90%;
    font-weight: bold;
    font-size: 35px;
    background-color: #dd9c62;
    border-color: #dd9c62;
  }
}
</style>
